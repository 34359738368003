import React from "react";

import Layout from "../components/layout";
import ThankYouWrapper from "../components/ThankYouWrapper";

import IconFacebook from "../images/icons/social/facebook-white.svg";
import IconTwitter from "../images/icons/social/twitter-white.svg";
import IconLinkedIn from "../images/icons/social/linkedin-white.svg";

const ThankYouPage2 = ({ data, location: { pathname } }) => (
  <Layout
    hideFooter
    footerBgClass="bg-white"
    meta={{
      seo: {
        title: "Thank you for reaching us!"
      },
      path: pathname
    }}
  >
    <div className="thank-you-page-2">
      <ThankYouWrapper
        title="Thank you for contacting us!"
        description="Your information has been submitted and we will get back to you soon."
        middleBlock={<h3 className="h3 pt-2 pb-4">Help us spread the word:</h3>}
        socialLinksBlock={
          <div className="social-btns">
            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fadamfard.com%2F" target="_blank" rel="noopener noreferrer" className="facebook">
              <IconFacebook />
              <span>Facebook</span>
            </a>
            <a
              href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fadamfard.com%2F&text="
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
            >
              <IconTwitter />
              <span>Twitter</span>
            </a>
            <a
              href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fadamfard.com%2F&title="
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
            >
              <IconLinkedIn />
              <span>LinkedIn</span>
            </a>
          </div>
        }
      />
    </div>
  </Layout>
);

export default ThankYouPage2;
